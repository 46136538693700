import { ReactComponent as GoogleLogo } from '@assets/googleLogo.svg';
import { ReactComponent as FbLogo } from '@assets/facebook.svg';
import { ReactComponent as ShopifyLogo } from '@assets/icon-shopify.svg';
import InputField from '@components/inputField';
import { useAuthStore } from '@container/Auth';
import UserLayout from '@modules/userLayout';
import { Link } from '@reach/router';
import { loginService } from '@services/user';
import {
  Button,
  Checkbox,
  DisplayText,
  Form,
  FormLayout,
  InlineError,
  TextContainer,
  ButtonGroup,
} from '@shopify/polaris';
import { useEffect, useState } from 'react';
import useSignIn from 'src/hooks/useSignIn';
import { navigate } from '@reach/router';
import { FIREBASE_METHOD } from '@helpers/constants';
import { ERROR_MAPPING, FIREBASE_ERROR_CODE_WHITELIST } from '@helpers/error.constant';

const Signin = () => {
  const [value, setValue] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [keepLogin, setKeepLogin] = useState(false);
  const [signinError, setSigninError] = useState(false);
  const { handleSignInWithFirebase } = useSignIn();
  const [formError, setFormError] = useState('');
  const { currentUser } = useAuthStore();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await loginService(value?.email, value?.password, keepLogin);
      window.location.href = '/';
    } catch (error) {
      setLoading(false);
      setFormError(error?.message);
    }
  };

  useEffect(() => {
    if (currentUser) navigate('/');
  }, [currentUser]);

  const handleChangeValue = (field, newValue) => {
    setFormError('');
    setValue((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleSocialSignIn = async (method) => {
    try {
      await handleSignInWithFirebase(method);
    } catch (error) {
      if (FIREBASE_ERROR_CODE_WHITELIST.includes(error?.code)) return null;
      setFormError(ERROR_MAPPING?.[error?.code] || error?.code);
    }
  };

  return (
    <UserLayout title={'Sign in'} showQuote>
      <div className="header-action">
        <div className="flex">
          <span>Need an account?</span>
          <Button primary onClick={() => navigate('/signup')}>Sign up</Button>
        </div>
      </div>
      <div className="form-container">
        <div className="form-header">
          <FormLayout>
            <TextContainer>
              <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall'}>
                Welcome back!
              </p>
            </TextContainer>
            <div className="form-login-methods">
              <ButtonGroup>
                <Button onClick={() => navigate('/shopify-connect')} icon={<ShopifyLogo />}>
                  Login with Shopify
                </Button>
                <Button onClick={() => handleSocialSignIn(FIREBASE_METHOD.GOOGLE)} icon={<GoogleLogo />}>
                  Login with Google
                </Button>
                <Button onClick={() => handleSocialSignIn(FIREBASE_METHOD.FACEBOOK)} icon={<FbLogo />}>
                  Login with Facebook
                </Button>
              </ButtonGroup>
            </div>
          </FormLayout>
        </div>
        <div className="form-divider">
          <span className="form-divider__line" />
          <span className="form-divider__text">OR</span>
          <span className="form-divider__line" />
        </div>
        <div className="form-content">
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <InputField
                value={value?.email}
                onChange={(v) => handleChangeValue('email', v)}
                label="Email address"
                type="email"
                autoComplete="email"
                placeholder={'Enter your email'}
              />
              <InputField
                value={value?.password}
                onChange={(v) => handleChangeValue('password', v)}
                label="Password"
                labelAction={{
                  content: 'Forgot password?',
                  onAction: () => navigate('/forgot-password'),
                }}
                type="password"
                placeholder={'Enter your password'}
              />
              <Checkbox label="Keep me logged in" checked={keepLogin} onChange={(e) => setKeepLogin(e)} />
              {signinError && <InlineError message="Email or password invalid, please try again." />}
              <InlineError message={formError} />
              <Button primary loading={loading} submit fullWidth={true} size="large" disabled={!value?.email || !value?.password}>
                Log in
              </Button>
              <div className="form-footer">
                <DisplayText size={'small'}>
                  Need an account?{' '}
                  <Link to="/signup">
                    <strong>
                      <u>Sign up</u>
                    </strong>
                  </Link>
                </DisplayText>
              </div>
            </FormLayout>
          </Form>
        </div>
      </div>
    </UserLayout>
  );
};

export default Signin;
