import Quote from "@components/quotes";
import FoxHomeLogo from "@components/foxHomeLogo";
import {TextStyle} from "@shopify/polaris";
import { useEffect } from "react";
import '@styles/users.scss'

const UserLayout = ({children, title, showQuote = false}) => {
  useEffect(() => {
    document.title = `${title} - FoxHome`;
    document.body.classList.add('user-page');
  }, []);

  let className = 'User-Layout'

  if (showQuote) {
    className += ' User-Layout--show-quote'
  }

	return (
		<div className={className}>
      {showQuote && <div className="User-Layout__quote">
        <Quote />
      </div>}
      <main className={'User-Layout__wrapper'}>
        <div className="User-Layout__content">
          <FoxHomeLogo />
          {children}
        </div>
        <Footer />
      </main>
    </div>
  )
}

const Footer = () => {
  return (
    <div className="User-Layout__footer">
      <div className={'flex flex-col items-start'}>
        <div className="trustpilot">
          <a href="https://www.trustpilot.com/review/foxecom.com" target={'_blank'} className={'full-width-link'} />
          <h4>
            <svg width="105" height="26" viewBox="0 0 70 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.39606 10.18L9.17529 9.47832L10.3365 13.0436L6.39606 10.18ZM12.7921 5.57172H7.89989L6.39606 0.982422L4.89222 5.57172H0L3.95947 8.41633L2.45563 13.0056L6.41509 10.161L8.85168 8.41633L12.7921 5.57172Z"
                fill="#00B67B" />
              <path
                d="M69.2441 4.95715V6.15641H65.4622V4.95715H69.2441ZM66.3959 3.38098H67.9463V9.55717C67.9463 9.76562 67.9777 9.92552 68.0406 10.0369C68.1062 10.1454 68.1919 10.2196 68.2975 10.2596C68.4032 10.2996 68.5203 10.3196 68.6488 10.3196C68.7458 10.3196 68.8344 10.3124 68.9143 10.2981C68.9971 10.2839 69.0599 10.271 69.1028 10.2596L69.364 11.4717C69.2812 11.5003 69.1627 11.5317 69.0085 11.5659C68.8572 11.6002 68.6716 11.6202 68.4517 11.6259C68.0634 11.6373 67.7136 11.5788 67.4024 11.4503C67.0911 11.3189 66.8442 11.1162 66.6614 10.8421C66.4815 10.568 66.393 10.2253 66.3959 9.81416V3.38098Z"
                fill="black" />
              <path
                d="M61.489 11.6644C60.8465 11.6644 60.2897 11.5231 59.8186 11.2404C59.3474 10.9577 58.9819 10.5622 58.7221 10.054C58.4651 9.54572 58.3366 8.9518 58.3366 8.27222C58.3366 7.59264 58.4651 6.99729 58.7221 6.48618C58.9819 5.97506 59.3474 5.57817 59.8186 5.29548C60.2897 5.0128 60.8465 4.87146 61.489 4.87146C62.1314 4.87146 62.6882 5.0128 63.1594 5.29548C63.6305 5.57817 63.9946 5.97506 64.2515 6.48618C64.5114 6.99729 64.6413 7.59264 64.6413 8.27222C64.6413 8.9518 64.5114 9.54572 64.2515 10.054C63.9946 10.5622 63.6305 10.9577 63.1594 11.2404C62.6882 11.5231 62.1314 11.6644 61.489 11.6644ZM61.4975 10.4223C61.8459 10.4223 62.1371 10.3267 62.3713 10.1354C62.6054 9.94119 62.7796 9.68135 62.8938 9.35584C63.0109 9.03032 63.0694 8.66769 63.0694 8.26794C63.0694 7.86533 63.0109 7.50127 62.8938 7.17575C62.7796 6.84738 62.6054 6.58612 62.3713 6.39195C62.1371 6.19778 61.8459 6.1007 61.4975 6.1007C61.1406 6.1007 60.8436 6.19778 60.6066 6.39195C60.3725 6.58612 60.1969 6.84738 60.0798 7.17575C59.9656 7.50127 59.9085 7.86533 59.9085 8.26794C59.9085 8.66769 59.9656 9.03032 60.0798 9.35584C60.1969 9.68135 60.3725 9.94119 60.6066 10.1354C60.8436 10.3267 61.1406 10.4223 61.4975 10.4223Z"
                fill="black" />
              <path d="M57.0258 2.76428V11.536H55.4753V2.76428H57.0258Z" fill="black" />
              <path
                d="M52.3304 11.5361V4.95729H53.8808V11.5361H52.3304ZM53.1099 4.02358C52.8643 4.02358 52.653 3.9422 52.476 3.77945C52.299 3.61383 52.2104 3.41538 52.2104 3.1841C52.2104 2.94996 52.299 2.75151 52.476 2.58875C52.653 2.42314 52.8643 2.34033 53.1099 2.34033C53.3583 2.34033 53.5696 2.42314 53.7438 2.58875C53.9208 2.75151 54.0093 2.94996 54.0093 3.1841C54.0093 3.41538 53.9208 3.61383 53.7438 3.77945C53.5696 3.9422 53.3583 4.02358 53.1099 4.02358Z"
                fill="black" />
              <path
                d="M44.791 14.003V4.95712H46.3158V6.04502H46.4057C46.4857 5.88512 46.5985 5.71522 46.7441 5.53534C46.8897 5.35259 47.0867 5.19697 47.3352 5.06848C47.5836 4.93713 47.9005 4.87146 48.286 4.87146C48.7943 4.87146 49.2525 5.00138 49.6609 5.26122C50.072 5.5182 50.3976 5.8994 50.6374 6.4048C50.8801 6.90735 51.0015 7.52411 51.0015 8.25509C51.0015 8.9775 50.883 9.59141 50.646 10.0968C50.409 10.6022 50.0863 10.9877 49.678 11.2532C49.2697 11.5188 48.8071 11.6516 48.2903 11.6516C47.9134 11.6516 47.6007 11.5887 47.3523 11.4631C47.1039 11.3375 46.904 11.1861 46.7527 11.0091C46.6042 10.8292 46.4885 10.6593 46.4057 10.4994H46.3415V14.003H44.791ZM46.3115 8.24652C46.3115 8.67197 46.3715 9.0446 46.4914 9.3644C46.6142 9.68421 46.7898 9.93405 47.0182 10.1139C47.2495 10.291 47.5293 10.3795 47.8577 10.3795C48.2003 10.3795 48.4873 10.2881 48.7186 10.1054C48.9499 9.91978 49.1241 9.66707 49.2411 9.34727C49.3611 9.02461 49.421 8.6577 49.421 8.24652C49.421 7.8382 49.3625 7.47557 49.2454 7.15862C49.1283 6.84167 48.9542 6.59326 48.7229 6.41337C48.4916 6.23348 48.2032 6.14353 47.8577 6.14353C47.5265 6.14353 47.2452 6.23062 47.0139 6.4048C46.7826 6.57898 46.607 6.82311 46.4871 7.13721C46.37 7.4513 46.3115 7.82107 46.3115 8.24652Z"
                fill="black" />
              <path
                d="M43.4952 4.95715V6.15641H39.7133V4.95715H43.4952ZM40.647 3.38098H42.1974V9.55717C42.1974 9.76562 42.2288 9.92552 42.2917 10.0369C42.3573 10.1454 42.443 10.2196 42.5487 10.2596C42.6543 10.2996 42.7714 10.3196 42.8999 10.3196C42.9969 10.3196 43.0855 10.3124 43.1654 10.2981C43.2482 10.2839 43.311 10.271 43.3539 10.2596L43.6151 11.4717C43.5323 11.5003 43.4138 11.5317 43.2596 11.5659C43.1083 11.6002 42.9227 11.6202 42.7028 11.6259C42.3145 11.6373 41.9647 11.5788 41.6535 11.4503C41.3423 11.3189 41.0953 11.1162 40.9125 10.8421C40.7326 10.568 40.6441 10.2253 40.647 9.81416V3.38098Z"
                fill="black" />
              <path
                d="M38.7905 6.69605L37.3771 6.85024C37.3371 6.70747 37.2672 6.57327 37.1672 6.44763C37.0702 6.32199 36.9388 6.22063 36.7732 6.14353C36.6076 6.06644 36.4048 6.02789 36.165 6.02789C35.8423 6.02789 35.5711 6.09785 35.3512 6.23776C35.1342 6.37767 35.0271 6.55899 35.03 6.78171C35.0271 6.97302 35.0971 7.12864 35.2399 7.24857C35.3855 7.36849 35.6253 7.467 35.9594 7.5441L37.0816 7.78395C37.704 7.91815 38.1666 8.13088 38.4693 8.42213C38.7748 8.71338 38.929 9.09457 38.9319 9.56571C38.929 9.97974 38.8077 10.3452 38.5678 10.6622C38.3308 10.9763 38.001 11.2218 37.5784 11.3989C37.1558 11.5759 36.6704 11.6644 36.1222 11.6644C35.3169 11.6644 34.6688 11.4959 34.1776 11.159C33.6865 10.8192 33.3938 10.3467 33.2996 9.74131L34.8115 9.59569C34.8801 9.89265 35.0257 10.1168 35.2484 10.2681C35.4711 10.4195 35.761 10.4951 36.1179 10.4951C36.4862 10.4951 36.7818 10.4195 37.0045 10.2681C37.2301 10.1168 37.3428 9.92977 37.3428 9.70705C37.3428 9.51859 37.27 9.36298 37.1244 9.24019C36.9816 9.11741 36.7589 9.02319 36.4562 8.95751L35.3341 8.72194C34.703 8.5906 34.2362 8.3693 33.9335 8.05807C33.6308 7.74397 33.4809 7.34708 33.4838 6.86737C33.4809 6.46191 33.5909 6.1107 33.8136 5.81374C34.0392 5.51392 34.3518 5.28263 34.7516 5.11988C35.1542 4.95427 35.6182 4.87146 36.1436 4.87146C36.9145 4.87146 37.5213 5.03564 37.9639 5.36401C38.4093 5.69238 38.6849 6.13639 38.7905 6.69605Z"
                fill="black" />
              <path
                d="M30.4451 8.76958V4.95764H31.9956V11.5364H30.4923V10.3672H30.4237C30.2752 10.7355 30.0311 11.0368 29.6913 11.2709C29.3544 11.505 28.9389 11.6221 28.4449 11.6221C28.0138 11.6221 27.6326 11.5264 27.3014 11.3351C26.973 11.141 26.716 10.8597 26.5304 10.4914C26.3448 10.1202 26.252 9.67188 26.252 9.14649V4.95764H27.8025V8.90664C27.8025 9.32352 27.9167 9.65475 28.1451 9.90031C28.3736 10.1459 28.6734 10.2687 29.0446 10.2687C29.273 10.2687 29.4943 10.213 29.7085 10.1016C29.9226 9.99025 30.0982 9.82464 30.2353 9.60478C30.3752 9.38206 30.4451 9.10366 30.4451 8.76958Z"
                fill="black" />
              <path
                d="M21.4708 11.5359V4.95708H22.9742V6.05355H23.0427C23.1626 5.67378 23.3682 5.38111 23.6595 5.17552C23.9536 4.96708 24.2891 4.86285 24.666 4.86285C24.7517 4.86285 24.8473 4.86714 24.953 4.8757C25.0615 4.88141 25.1514 4.89141 25.2228 4.90568V6.33195C25.1571 6.3091 25.0529 6.28912 24.9101 6.27198C24.7702 6.252 24.6346 6.242 24.5032 6.242C24.2206 6.242 23.9664 6.30339 23.7409 6.42618C23.5181 6.5461 23.3425 6.71314 23.214 6.9273C23.0855 7.14145 23.0213 7.38844 23.0213 7.66827V11.5359H21.4708Z"
                fill="black" />
              <path d="M13.8746 4.09632V2.76428H20.8731V4.09632H18.1619V11.536H16.5858V4.09632H13.8746Z"
                    fill="black" />
            </svg>
            <span>4.9 rating</span>
          </h4>
        </div>
        <TextStyle>Copyright &copy; 2024 <a href={'https://foxecom.com'} target={'_blank'}>FoxEcom</a>. All Rights
          Reserved.</TextStyle>
      </div>
      <div className={'flex'}>
        <a href="https://docs.foxecom.com/foxhome" target={'_blank'}>What is FoxHome?</a>
      </div>
    </div>
  )
}

export default UserLayout
