import { ReactComponent as GoogleLogo } from '@assets/googleLogo.svg';
import { ReactComponent as FbLogo } from '@assets/facebook.svg';
import { ReactComponent as ShopifyLogo } from '@assets/icon-shopify.svg';
import InputField from '@components/inputField';
import { FIREBASE_METHOD, MIN_LENGTH_PASSWORD } from '@helpers/constants';
import { EMAIL_EXISTED, ERROR_MAPPING, FIREBASE_ERROR_CODE_WHITELIST } from '@helpers/error.constant';
import UserLayout from '@modules/userLayout';
import { showToast } from '@plugins/toast';
import { Link, navigate } from '@reach/router';
import { signUpService } from '@services/user';
import { Button, ButtonGroup, DisplayText, Form, FormLayout, InlineError, TextContainer } from '@shopify/polaris';
import { useMemo, useState } from 'react';
import useSignIn from 'src/hooks/useSignIn';

const Signup = () => {
  const [value, setValue] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    firstName: '',
    lastName: '',
  });

  const [fieldError, setFieldError] = useState({
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [formError, setFormError] = useState('');

  const { handleSignInWithFirebase } = useSignIn();

  const handleChangeValue = (field, value) => {
    setFormError('');
    setFieldError((prev) => ({ ...prev, [field]: '' }));

    setValue((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (value?.password?.length < MIN_LENGTH_PASSWORD) {
        setFieldError((prev) => ({ ...prev, password: `Your password must have at least ${MIN_LENGTH_PASSWORD} characters` }));
        return;
      }
      if (value?.password !== value?.repeatPassword) {
        setFieldError((prev) => ({ ...prev, repeatPassword: 'Password does not match!' }));
        return;
      }
      setLoading(true);
      await signUpService({ email: value?.email, password: value?.password, firstName: value?.firstName, lastName: value?.lastName });
      setLoading(false);
      navigate('/add-store');
      showToast({
        message: 'Sign up successfully',
      });
    } catch (error) {
      if (error?.message === EMAIL_EXISTED) {
        setFieldError((prev) => ({
          ...prev,
          email: ERROR_MAPPING?.[error.message],
        }));
      } else {
        setFormError(error?.message);
      }
      setLoading(false);
    }
  };

  const disabled = useMemo(() => {
    return [value?.email, value?.password, value?.repeatPassword]?.findIndex((item) => !item) !== -1;
  }, [value]);

  const handleSocialSignIn = async (method) => {
    try {
      await handleSignInWithFirebase(method);
    } catch (error) {
      if (FIREBASE_ERROR_CODE_WHITELIST.includes(error?.code)) return null;
      setFormError(ERROR_MAPPING?.[error?.code] || error?.code);
    }
  };

  return (
    <UserLayout title={'Sign up account'} showQuote>
      <div className="header-action">
        <div className="flex">
          <span>Already have an account?</span>
          <Button primary onClick={() => navigate('/login')}>Sign in</Button>
        </div>
      </div>
      <div className="form-container">
        <div className="form-header">
          <FormLayout>
            <TextContainer>
              <p className={'Polaris-DisplayText Polaris-DisplayText--sizeSmall'}>
                Try <strong>Minimog</strong> theme and <strong>Megamog</strong> theme
                for free 100%. <br />No
                credit card needed.
              </p>
            </TextContainer>
            <div className="form-login-methods">
              <ButtonGroup>
                <Button onClick={() => navigate('/shopify-connect?action=sign-up')} icon={<ShopifyLogo />}>
                  Sign up with Shopify
                </Button>
                <Button onClick={() => handleSocialSignIn(FIREBASE_METHOD.GOOGLE)} icon={<GoogleLogo />}>
                  Sign up with Google
                </Button>
                <Button onClick={() => handleSocialSignIn(FIREBASE_METHOD.FACEBOOK)} icon={<FbLogo />}>
                  Sign up with Facebook
                </Button>
              </ButtonGroup>
            </div>
          </FormLayout>
        </div>
        <div className="form-divider">
          <span className="form-divider__line" />
          <span className="form-divider__text">OR</span>
          <span className="form-divider__line" />
        </div>
        <div className="form-content">
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <FormLayout.Group condensed>
                <InputField
                  value={value?.firstName}
                  label="First name"
                  onChange={(newValue) => handleChangeValue('firstName', newValue)}
                  autoComplete="off"
                  placeholder={'Your first name'}
                />
                <InputField
                  value={value?.lastName}
                  label="Last name (optional)"
                  onChange={(newValue) => handleChangeValue('lastName', newValue)}
                  autoComplete="off"
                  placeholder={'Your last name'}
                />
              </FormLayout.Group>
              <InputField
                value={value?.email}
                onChange={(newValue) => handleChangeValue('email', newValue)}
                label="Email address"
                placeholder={'Enter your email'}
                type="email"
                autoComplete="email"
                error={fieldError?.email}
              />
              <InputField
                value={value?.password}
                onChange={(newValue) => handleChangeValue('password', newValue)}
                label="Password"
                type="password"
                placeholder={'Enter your password'}
                error={fieldError?.password}
                helpText={'8 characters minimum'}
              />
              <InputField
                value={value?.repeatPassword}
                onChange={(newValue) => handleChangeValue('repeatPassword', newValue)}
                label="Confirm password"
                type="password"
                placeholder={'Repeat your password'}
                error={fieldError?.repeatPassword}
              />
              <Button primary loading={loading} submit fullWidth={true} size="large" disabled={disabled}>
                Sign up with email
              </Button>
              <p className={'text-center'}>
                By signing up, you agree to our
                {' '}
                <a target={'_blank'} href="https://docs.foxecom.com/legal/legal/terms-of-service">
                  <u>Terms of Service</u>.
                </a>
              </p>
              <InlineError message={formError} />
              <div className="form-footer">
                <DisplayText size={'small'}>
                  Already have an account?{' '}
                  <Link to="/login">
                    <strong>
                      <u>Login</u>
                    </strong>
                  </Link>
                </DisplayText>
              </div>


            </FormLayout>
          </Form>
        </div>
      </div>
    </UserLayout>
  );
};

export default Signup;
